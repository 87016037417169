/* @import url("https://fonts.googleapis.com/css?family-Jost:wght@300&display-swap"); */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Jost", sans-serif;
}

html {
    font-size: 62.5%;
}

body {
    background-color: rgb(69 57 248 / 5%);
}

a {
    text-decoration: none;

}

li {
    list-style: none;
}

.logo h2 {
    margin-top: 1rem;
    font-size: 4.5rem;
    font-weight: 400;

    background-color: -webkit-linear-gradient(#eee, rgb(69, 57, 248));


}

.main-nav {
    width: 100%;
    height: 7rem;
    display: grid;
    grid-template-columns: 10rem 1fr 2fr 1fr 10rem;
    box-shadow: 10px 5px 5px grey;
    background-color: black;
    color: white;


}

.logo {

    grid-column: 2/3;
    justify-content: start;

}

.logo h2 span {
    font-size: 5rem;
    font-weight: 500;
}


.main-container {
   border-radius: 1rem;
    height: 70rem;
    width: 72%;
    background-color: #282c341a;
    margin: 30px auto;
    
}

.section{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  
    place-items: center;
    height: 70rem;
    width: 100%;
  
}

.container {
    background-color: #e8eaf6;
    
    width: 40rem;
    height: 20rem;
    margin: 1.6rem 0;
    border-radius: 1.2rem;

    filter: drop-shadow(0.3rem 0.5rem 0.6rem grey);
    text-align: center;

}

.name_sec {
    position: relative;
    top:1rem;
    margin: 1.2rem 0;
}


.name_sec h1 {
    font-size: 2.3rem;
    

}

.price_sec {
    margin: 1rem 0;

}

.price_sec h2 {
    font-size: 1.5rem;

}

.stock_sec {
    margin: 1.9rem 0 0 0;

}

.stock_sec h2 {
    font-size: 1.7rem;

}

.remove-item {
  
}



.cart_buttons{
    display: flex;
    justify-content: space-evenly;
  position: relative;
  top:2.2rem
}


.btn {
    padding: 9px 20px;
    border-radius: .5rem;
    background-color: #1a237ec2;
    color: white;
    border: none;
    text-transform: uppercase;
}

.btn:hover{
   
    background-color: black;
    
}
.btn-delete {
    padding: 9px 20px;
    border-radius: .5rem;
    background-color: #1a237ec2;
    color: white;
    border: none;
    text-transform: uppercase;
}

.btn-delete:hover{
    background-color:red;
}

.bottom{
    display: flex;
   
   justify-content: end;
   flex-direction: column;
   text-align: end;
    width: 75%;
    height: 7rem;
    margin: 9px auto;

}

.cart_total{
    margin: 1rem 8rem 1rem 0;
}
.cart_total p {
font-size: 2.4rem;
}

.cart_total .cart_price {
font-weight: bold;
    }

.buttons{
    margin: 0 8rem 0 0;
}
.btn1{
    padding: 9px 24px;
    border-radius: .5rem;
    background-color: #f83939db;
    color: white;
    border: none;
    text-transform: uppercase;
}